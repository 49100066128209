import React from "react";
import styled from "styled-components";
import { lucid } from "../theme/colors";

const MenuBar = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;

`;

const MenuItem = styled.li`
  float: left;
`;

const textColor = lucid.colors.secondary;
const highlightTextColor = lucid.colors.tertiary;

const MenuLink = styled.a`
  display: block;
  text-align: center;
  padding: 14px 16px;
text-decoration: none;
color: ${textColor};
font-family: VisionRegular;
font-size: 20px;

  &:hover {
    color: ${highlightTextColor};
    font-size: 25px;
  }
`;

function Menu() {
  return (
    <MenuBar>
      <MenuItem>
        <MenuLink href="#VisualArt">Visual Art</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink href="#Writing">Writing</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink href="#Code">Code</MenuLink>
      </MenuItem>
      <MenuItem>
        <MenuLink href="#Music">Music</MenuLink>
      </MenuItem>
    </MenuBar>
  );
}

export default Menu;
