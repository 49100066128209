import { Buffer } from "buffer";

export const client_id = "0abf6887749e454597c46fd0e0b46324"; // Your client id
const client_secret = "1761caf7bc0245bfa54590faee3d6cab"; // Your secret
export const redirectUri = "http://localhost:3000/callback"; // Your redirect uri

// your application requests authorization

export function getSpotifyUserData(userId: string, token: string, callback: Function) {
  const options = {
    url: `https://api.spotify.com/v1/users/${userId}`,
    headers: {
      Authorization: "Bearer " + token,
    },
    json: true,
  };
  fetch(options.url, {
    method: "GET",
    headers: options.headers,
  })
    .then((response) => response.json())
    .then((data) => callback(null, data))
    .catch((error) => callback(error, null));
}

// Example usage
const authOptions = {
  url: "https://accounts.spotify.com/api/token",
  headers: {
    Authorization:
      "Basic " +
      Buffer.from(client_id + ":" + client_secret).toString("base64"),
    "Content-Type": "application/x-www-form-urlencoded",
  },
  form: {
    grant_type: "client_credentials",
  },
  json: true,
};
const apiOptions = {
  /* API options */
};

// use for public metadata
// getTokenAndAccessAPI(
//   authOptions,
//   apiOptions,
//   function (error: any, response: any, body: any) {
//     if (!error && response.statusCode === 200) {
//       console.log(body);
//     } else {
//       console.error("Error:", error);
//     }
//   }
// );

export function ListObsession() {
  const token = localStorage.getItem("access_token");
  const options = {
    url: "https://api.spotify.com/v1/me/top/artists",
    headers: {
      Authorization: "Bearer " + token,
    },
    json: true,
  };
  fetch(options.url, {
    method: "GET",
    headers: options.headers,
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.log(error));
}

function generateRandomString(length: number): string {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

async function generateCodeChallenge(codeVerifier: string) {
  const data = new TextEncoder().encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode.apply(null, [...new Uint8Array(digest)]))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
}

function getTokenAndAccessAPI(
  authOptions: any,
  apiOptions: any,
  callback: Function
) {
  const formData = new URLSearchParams();
  formData.append("grant_type", "client_credentials");
  fetch(authOptions.url, {
    method: "POST",
    headers: authOptions.headers,
    body: formData.toString(),
  })
    .then((response) => {
      if (response.ok) {
        console.log("Response:", response);
        return response.json();
      } else {
        console.error("Error:", response);
        throw new Error("Authentication request failed");
      }
    })
    .then((data) => {
      console.error("Data:", data);
      const token = data.access_token;
      apiOptions.headers["Authorization"] = "Bearer " + token;

      return fetch(apiOptions.url, {
        method: "GET",
        headers: apiOptions.headers,
      });
    })
    .then((response) => response.json())
    .then((data) => callback(null, data))
    .catch((error) => callback(error, null));
}

function LoginWithSpotify() {
  // use for user metadata
  console.log("LoginWithSpotify");
  const codeVerifier = generateRandomString(128);
  navigator.clipboard.writeText(codeVerifier);

  generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    const state = generateRandomString(16);
    const scope =
      "user-read-private user-read-email user-read-recently-played user-top-read user-read-playback-state user-modify-playback-state user-read-currently-playing playlist-read-private playlist-read-collaborative playlist-modify-public playlist-modify-private streaming app-remote-control user-library-read user-library-modify user-read-playback-position user-read-recently-played user-follow-read user-follow-modify";
    const clientId = client_id;

    localStorage.setItem("code_verifier", codeVerifier);

    const args = new URLSearchParams({
      response_type: "code",
      client_id: clientId,
      scope: scope,
      redirect_uri: redirectUri,
      state: state,
      code_challenge_method: "S256",
      code_challenge: codeChallenge,
    });

    window.location.href =
      "https://accounts.spotify.com/authorize?" + args.toString();

  });
}
export default LoginWithSpotify;
