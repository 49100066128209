import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  redirectUri,
  client_id,
  getSpotifyUserData,
} from "../scripts/spotifyAuth";

export async function getAccessToken(
  clientId: string,
  code: string
): Promise<string> {
  const verifier = localStorage.getItem("code_verifier");

  const params = new URLSearchParams();
  params.append("client_id", clientId);
  params.append("grant_type", "authorization_code");
  params.append("code", code);
  params.append("redirect_uri", "http://localhost:3000/callback");
  params.append("code_verifier", verifier!);

  const result = await fetch("https://accounts.spotify.com/api/token", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: params,
  });

  const { access_token } = await result.json();
  return access_token;
}

function CallbackHandler() {
  const location = useLocation();
  // Parse URL parameters from the location object
  // Extract and process the necessary data

  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get("code");
  useEffect(() => {
    const codeVerified = localStorage.getItem("code_verifier");
    if (code && codeVerified) {
      getAccessToken(client_id, code).then((token) => {
        console.log("token:", token);
        localStorage.setItem("access_token", token);
        getSpotifyUserData("hiftikha", token, (error: any, data: any) => {
            if (error) {
              console.error("Error:", error);
            } else {
              console.log("Data:", data);
            }
          });
      });

      const token = localStorage.getItem("access_token");
    }
  }, []);

  // navigator.clipboard.writeText(codeVerified);
  // const params = new URLSearchParams();
  // params.append("client_id", client_id);
  // params.append("grant_type", "authorization_code");
  // params.append("code", code);
  // params.append("redirect_uri", redirectUri);
  // params.append("code_verifier", codeVerified!);

  // fetch("https://accounts.spotify.com/api/token", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  //   body: params,
  // })
  //   .then((response: Response) => {
  //     if (!response.ok) {
  //       throw new Error("HTTP status " + response.status);
  //     }
  //     console.log("response:", response);
  //     return response.json();
  //   })
  //   .then((data: { access_token: string }) => {
  //     console.log("token:", data);
  //     localStorage.setItem("access_token", data.access_token);
  //   })
  //   .catch((error: Error) => {
  //     console.error("Error:", error);
  //   });
  return <div>{/* Render any necessary content */}</div>;
}

export default CallbackHandler;
