import Obsession from "../Spotify/Obsession";
import { ObsessionType } from "../types/SpotifyTypes";

// create dummy data
export const dummyObsessions = [
    {
        type: ObsessionType.artists,
        name: "The Beatles",
        image: "https://i.scdn.co/image/ab6761610000e5ebc5b6b5b6b5b6b5b6b5b6b5b6",
        description: "The Beatles were an English rock band formed in Liverpool in 1960. The group, whose best-known line-up comprised John Lennon, Paul McCartney, George Harrison and Ringo Starr, are regarded as the most influential band of all time.",
        link: "https://open.spotify.com/artist/3WrFJ7ztbogyGnTHbHJFl2?si=8e9e9e9e9e9e9e9e"
    },
    {
        type: ObsessionType.artists,
        name: "The Ambientalist",
        image: "https://i.scdn.co/image/ab67616d0000b273b5b6b5b6b5b6b5b6b5b6b5b6",
        description: "The Ambientalist is a music producer and composer from the UK. He is known for his ambient and electronic music, and has released several albums and EPs.",
        link: "https://open.spotify.com/artist/3WrFJ7ztbogyGnTHbHJFl2?si=8e9e9e9e9e9e9e9e"
    },
    {
        type: ObsessionType.artists,
        name: "Andy Leech",
        image: "https://i.scdn.co/image/ab67616d0000b273b5b6b5b6b5b6b5b6b5b6b5b6",
        description: "Andy Leech is a music producer and composer from the UK. He is known for his ambient and electronic music, and has released several albums and EPs.",
        link: "https://open.spotify.com/artist/3WrFJ7ztbogyGnTHbHJFl2?si=8e9e9e9e9e9e9e9e"
    },
]