import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Controls from "./components/Controls";
import { dummyObsessions } from "./constants/SpotifyConstants";
import CallbackHandler from "./Spotify/CallbackHandler";
import LoginWithSpotifyAlert from "./Spotify/LoginWithSpotifyAlert";
import Obsession from "./Spotify/Obsession";
import { lucid, themeContext } from "./theme/colors";
import { LastfmPeriodControls } from "./types/LastfmTypes";
import { ObsessionObject } from "./types/SpotifyTypes";
import Spline from "@splinetool/react-spline";
import { styled } from "styled-components";
import Menu from "./components/Menu";

function App() {
  const [objects, setObjects] =
    React.useState<ObsessionObject[]>(dummyObsessions);
  const [loading, setLoading] = React.useState<boolean>(true);

  return (
    <Router>
      <Routes>
        <Route path="/callback" Component={CallbackHandler} />
      </Routes>
      {/* Additional routes go here */}
      <themeContext.Provider value={lucid}>
        <div
          className="App"
          style={{
            backgroundColor: lucid.colors.primary,
            height: "100vh",
            width: "100vw",
          }}
        >
          {/* <LoginWithSpotifyAlert setObsession={setObjects} /> */}
          {/* write a component that lists elements from a list */}
          {/* {<Obsession objects={objects} />}
          {<Controls options={
            LastfmPeriodControls
          }/>} */}
          <div
            style={{
              height: "80vh",
              width: "100vw",
              padding: 30,
            }}
          >
            <Spline scene="https://prod.spline.design/JXQyNNLjt-u8e0sW/scene.splinecode" />
          </div>

          <div style={{
            display: "flex",
            width: "100vw",
            justifyContent: "space-between"
            }}>
            <Menu/>
            <div style={styles}>
              <h1 className="upper-gradient-text" style={titleStyles}>
                Roon
              </h1>
              <h1
                className="bottom-gradient-text"
                style={{
                  ...titleStyles,
                  paddingBottom: 50,
                }}
              >
                Vision
              </h1>
            </div>
          </div>
        </div>
      </themeContext.Provider>
    </Router>
  );
}

export const styles = {
  // add styles to take up the entire screen
  display: "flex",
  // add styles to align the content to the bottom
  flexDirection: "column" as "column",
  gap: -10,

  // align the content to the bottom
  justifyContent: "space-between",
  alignItems: "flex-end",
};

export const titleStyles = {
  color: lucid.colors.secondary,
  // add font family that we exported in App.css
  fontFamily: "VisionRegular",
  fontSize: "2.2vh",
  margin: 0,
  paddingRight: 50,
};

export default App;
