import { createContext } from 'react';

export const lucid = {
    colors: {
        primary: '#0A0707',
        secondary: '#D17C78',
        tertiary: '#F2E9E4',
        quaternary: "#131313",
    }
}

export const themeContext = createContext(lucid);