// create a type for the list of objects
export enum ObsessionType {
    "artists",
    "tracks",
    "mood",
    "muzyscape"
} 

export type ObsessionObject = {
    type : ObsessionType,
    name : string,
    image : string,
    description : string,
    link : string

}

interface LastFmResponse {
  recenttracks: Recenttracks;
}

interface Recenttracks {
  track: LastfmTrack[];
  '@attr': LastFmResponseMetadata;
}

interface LastFmResponseMetadata {
  user: string;
  totalPages: string;
  page: string;
  perPage: string;
  total: string;
}

interface LastfmTrack {
  artist: Artist;
  streamable: string;
  mbid: string;
  album: Artist;
  name: string;
  '@attr'?: Attr;
  url: string;
  date?: Date;
}

interface LastfmDate {
  uts: string;
  '#text': string;
}

interface Attr {
  nowplaying: string;
}

interface Artist {
  mbid: string;
  '#text': string;
}